<template>
	<div>
		<div class=" benefiestab">
			<div class="tils">员工福利购</div>
			<div class="tab-bar">
				<div class="tab-item" v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }"
					@click="changeTab(index)">
					{{ tab.title }}
				</div>
			</div>
		</div>
		<div class="centsns">
			<div>
				<img :src="carouselSlides[activeTab]['image']" alt="Slide Image" />
			</div>

			<div class="product-list">
				<div class="titles">精选产品</div>
				<div class="cargo-list" v-if="cargoList.length">
					<div class="goods-info">
						<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id"
							@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
							<img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })"
								@error="imageError(index)" />
							<div class="price-wrap">
								<div class="price">
									<p>￥</p>
									{{ item.discount_price }}
								</div>
							</div>
							<div class="goods-name">{{ item.goods_name }}</div>
						</div>
					</div>
				</div>
				<div class="empty" v-else>
					<div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import {
		goodsSkuPage
	} from "@/api/goods/goods"
import request from "@/utils/http";
	export default {
		data() {
			return {
				activeTab: 0, // 当前选中的选项卡索引
				tabs: [{
						title: '茶歇福利'
					},
					{
						title: '医疗保健'
					},
					{
						title: '日用福利'
					},
					{
						title: '员工宿舍'
					},
					{
						title: '劳保物资'
					},
					{
						title: '户外团建'
					},
				],
				carouselSlides: [{    
						image: require('../../assets/images/benefits/brand1.jpg')
					},
					{
						image:require('../../assets/images/benefits/brand2.jpg')
					},
					{
						image:require('../../assets/images/benefits/brand3.jpg')
					},
					{
						image:require('../../assets/images/benefits/brand4.jpg')
					},
					{
						image:require('../../assets/images/benefits/brand5.jpg')
					},
					{
						image:require('../../assets/images/benefits/brand6.jpg')
					},
				],
				cargoList: [],
			};
		},
		created() {
			this.getIndexGoodsList();
		},
		methods: {
			changeTab(index) {
				this.activeTab = index;
				this.getIndexGoodsList()
			},
			getIndexGoodsList() {
				var benefits = this.activeTab + 1;
				goodsSkuPage({
						benefits: benefits
					})
					.then(res => {
						this.cargoList = res.data.list
						console.log(this.cargoList)
					})

			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-main{
		width:100% !important
	}
	.benefiestab {
		padding: 40px 0;
		background: url('../../../src/assets/images/benefits.jpg');
	}

	.benefiestab .tils {
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		height: 60px;
	}

	.tab-bar {
		display: flex;
		justify-content: center;

	}

	.tab-item {
		padding: 10px;
		cursor: pointer;
		width: 100px;
		text-align: center;
		font-size: 16px;
		font-weight: bold;
	}

	.tab-item.active {
		font-weight: bold;
		background: #fff;
		color: #ff547b;
		border-radius: 5px;
	}

	.centsns {
		width: 1200px;
		margin: 0 auto;
		background: #fff;
		padding: 20px 15px
	}

	.product-list {
		margin-top: 40px;
	}

	.product-list .titles {
		background: #f7f7f7;
		height: 40px;
		line-height: 40px;
		padding-left: 20px;
	}

	.cargo-list {
		border: 2px solid #f7f7f7;
		border-bottom: 0;
	}

	.goods-info {
		margin-top: 5px;
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 220px;
			margin: 10px 20px 0 0;
			padding-top: 10px;
			position: relative;
			padding-bottom: 5px;

			&:nth-child(4n) {
				margin-right: initial !important;
			}

			&:hover {
				border: 1px solid $base-color;
			}

			.img-wrap {
				width: 198px;
				height: 198px;
				cursor: pointer;
				padding: 10px;
			}

			.goods-name {
				padding: 0 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
				height: 50px;
				cursor: pointer;

				&:hover {
					color: $base-color;
				}
			}

			.price-wrap {
				padding: 0 10px;
				display: flex;
				align-items: center;

				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;

					p {
						font-size: $ns-font-size-base;
						display: flex;
						align-items: flex-end;
					}
				}

				.market-price {
					color: #838383;
					text-decoration: line-through;
					margin-left: 10px;
				}
			}

			.sale-num {
				padding: 0 10px;
				display: flex;
				color: #838383;
				font-size: $ns-font-size-sm;

				p {
					color: #4759a8;
				}
			}

			.shop_name {
				padding: 0 10px;
				display: flex;
				color: #838383;
			}

			.saling {
				padding: 0 10px;
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;

				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}

				.is_own {
					color: #ffffff;
					background: #ff850f;
					border: 1px solid;
					margin-right: 5px;
					display: flex;
					align-items: center;
					padding: 3px 4px;
					border-radius: 2px;
				}

				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px 3px;
				}
			}

			.item-bottom {
				display: flex;
				margin-top: 5px;

				.collection {
					flex: 1;
					border: 1px solid #e9e9e9;
					border-right: none;
					border-left: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}

				.cart {
					flex: 2;
					border: 1px solid #e9e9e9;
					border-right: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}

	.empty {
		margin-top: 45px;
	}
</style>